// tables
.ZMSTable {
   width: 100%;

   margin-top: $large-pad;
   margin-bottom: $large-pad;

   th:not(:first-child),
   td:not(:first-child) {
      text-align: right;
   }
}





// break on smallest screens
@include media-breakpoint-down(xs) {
   .ZMSTable {
     tr {
       display: table;  width:100%;
     }
     td {    
       display: table-row;
       text-align: center !important;  
//       padding-right: $small-pad !important;
       img {
          max-width: 100%;;
          margin: 0 auto;
       }
     }
     th {
       display: table-row;
     }
     tr {
       margin-bottom: $large-pad;
     }
   }
}

