/* define any font here */

@font-face {
  font-display: swap;
}

// Glyhpicons
@import "../node_modules/glyphicons-halflings/scss/glyphicons-halflings.scss";

// MDI
@import "../bower_components/mdi/scss/materialdesignicons.scss";

// Lato (all)
@import "../node_modules/lato-font/scss/lato-font.scss";

// SourceSansPro
// @import "../bower_components/SourceSansPro/SourceSansPro.css";

// OpenSans
@import "../node_modules/opensans-npm-webfont/style.scss";

// Roboto
@import "../node_modules/roboto-npm-webfont/light/style.scss";

// FA
@import "../node_modules/font-awesome/scss/font-awesome.scss";

// medical icons
@import "../bower_components/webfont-medical-icons/packages/webfont-medical-icons/wfmi-style.css";

