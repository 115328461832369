// ZMS custom modifyers


.ZMSButton {
   margin-top: $medium-pad;
   margin-bottom: $large-pad;
}

.TOC_Index {
   margin-top: $large-pad;
}


// srg_cal_timestamp
span.srg_timestamp {
   font-size: 0.75rem;
}


// card w image

.card {
    max-width: 100%;
}

.card-text {
   overflow-wrap: break-word;
   word-wrap: break-word;
}


.card.infolink {
   @include media-breakpoint-down(sm) {
      margin-bottom: $large-pad;
      .topimg {
         img {
            width: 100% !important;
         }
      }
      h4 {
         margin-top: $medium-pad;
      }
   }
}


.syshow {
   margin-bottom: $large-pad;
   &.halfwidth {
      max-width: 50%;
      @include media-breakpoint-down(md) {
         max-width: 100%;
      }
   }

   .carousel {
      overflow: hidden;
     .carousel-caption {
        bottom: 0;
        left: 0;
        width: 100%;
        background: transparent; /* Fallback IE 6-8 */
        background-color: rgba(0, 0, 0, .25);
        h5.item-title { 
          font-weight: bold; 
          color: $robert_main_medium;
          @extend .shadow_text;
        }
        p.item-description {
           padding-bottom: $medium-pad;
           a {
             color: $light-grey !important;
           }
        }
       .carousel-item > img {
       }
     }
   }
}


ul.ausstattung {
   width: 100%;

   @include media-breakpoint-up(sm) {
      column-count: 2;
      -moz-column-count: 2;
      -webkit-column-count: 2;
   }

   margin-bottom: $medium-pad;
   padding: $small-pad;
   background-color: $robert_main_light;


   li {
      &:before {
         color: $green;
         font-weight: bold;
         padding-right: $mini-pad;
      }
   }
}


// formats
.alert {
   @each $color, $value in $theme-colors {
      &.alert-#{$color} {
         a {
            color: darken($value, 10%);
            font-weight: $alert-link-font-weight;
         }
      }
   }
}

// products
div.prod_timespan {
   margin-bottom: $large-pad;

   .date,
   .custom {
      font-weight: bold;
   }
}

div.prod_legend {
   margin-top: $large-pad;
}

// angebote / products


div.text_sub {
   margin-top: $large-pad;
   margin-bottom: $large-pad;
//   font-weight: bold;
}


.prod_free_content {
   margin-top: $large-pad;
   table {
      width: 100% !important;

      td,
      th {
         text-align: left !important;
         width: 30% !important;

         & + td,
         & + th {
            width: auto;
            text-align: right !important;
         }
      }

      // break on smallest screens
      @include media-breakpoint-down(xs) {
         tr {
            display: table;  width:100%;
         }

         th {
            background-color: $robert_main_light;
         }

         td,
         th {
            display: table-row;
            text-align: center !important;
//          padding-right: $small-pad !important;

            & + td,
            & + th {
               text-align: center !important;
            }

            img {
               max-width: 100%;;
               margin: 0 auto;
            }
         }
         th {
            display: table-row;
         }
         tr {
            margin-bottom: $large-pad;
         }
      }
   }
}


.prod_teaser_action {
   margin-top: $medium-pad;
   margin-left: $large-pad;
//   width: 30%;
   @include media-breakpoint-down(xs) {
//      width: 80%;
   }
   z-index: 600;
   margin-right: $medium-pad;
   padding: $small-pad;
   background-color: $robert_main_light;
   @include rounded();
   @include rotate(10deg);
   @extend .shadow_light;
   h5 {
      cont-size: rem(20);
      color: $red;
      font-weight: bold;
      font-style: none;
   }
   .teaser_text_sub {

   }
}


div.ad_banner {
  margin-bottom: $large-pad;
  margin-top: $large-pad;
  img {
     width: 100% !important;
  }
}

