// footer

footer {
   div.footer_con {
      .jumbotron {

         // no shadows
         -webkit-box-shadow: none !important;
         box-shadow: none !important;

         color: white !important;
         @extend .shadow_text;

         font-weight: 450;
         strong,
         b {
            font-weight: 600;
         }
         a,
         a:link {
           color: white;          
         }

         .addr,
         .openinghours {
            a,
            a:link {
               @include alpha-background-color(rgba(black, 0.1), $base_color);
               @include rounded( rem(4));
               padding: rem(4);
               color: white;
            }
         }


         &.first {
            padding-top: $large-pad;
         }
         &.last {
            padding-bottom: 0;
            margin-bottom: 0;
         }

         h3,
         h4 {
            border-bottom: 1px solid $light_grey;
            color: $white;
            font-size: rem(18);
            font-weight: 650;
            line-height: 27px;
            text-transform: uppercase;
            padding-top: 0;
            margin-top: 0;
            margin-bottom: $medium-pad; 
            //padding: 0 0 10px;
         }
         h4 { font-size: rem(16); }

         .pgp_fingerprint {
            font-weight: 400;
            font-size: rem(12); 
         }
      }
   }
   

//      .company {
//         padding-top: $medium-pad;
//         padding-bottom: $medium-pad;
//
//         & > span {
//            word-wrap: normal;
//         }
//         @extend .shadow_text;

//   margin-top: rem(50);
//   @include media-breakpoint-down(md) {
//      margin-top: $medium-pad;
//   }
//   @include media-breakpoint-down(sm) {
//      margin-top: $small-pad;
//   }

//      color: $medium-grey;
//      background-color: $robert_main;
//      @extend .shadow_mini;
//      @extend .shadow_text;

   .copyright {
      margin-top: $small-pad;
      font-size: rem(12);
      text-align: center;
      color: $medium-grey;
      a,
      a:link {
         color: $medium-grey;
         color: lighten($medium-grey, 15%);
      }
   }
  .footer_image {
     img {
        width: 100% !important;
        max-width: 100% !important;
        height: auto;
      }
  }
}
