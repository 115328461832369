body {
//   font-family: "Open Sans", sans-serif;
   font-family: 'Roboto', sans-serif;
}

.topic_nav,
.navbar,
.breadcrumb {
   font-family: 'Roboto', sans-serif;
}
      
h1,h2,h3,h4,h5 {
     text-transform: uppercase;
     font-family: "Open Sans", sans-serif;
//   font-family: "SourceSansPro";
//   font-family: 'Roboto', sans-serif;
//   font-weight: bold;
   color: $robert_main_dark;
//   color: $black;
//   font-style: italic;
//   padding-top: $large-pad;
}

.navbar-nav,
.navbar-brand {
   text-transform: uppercase;
}

.content {
   & > h1,
   & > h2,
   & > h3 {
      
      &:not(:first-child) {
         margin-top: $large-pad;
      }
   }
}

.head_description {
   font-style: italic;
   color: $medium-grey;
}

h1 {
   @include media-breakpoint-down(sm) {
      font-size: 1.8em;
   }
}

h2 {
   @include media-breakpoint-down(sm) {
      font-size: 1.5em;
   }
}


.jumbotron {
   font-family: 'Roboto', sans-serif;
   color: $black;
   h1,h2,h3,h4,h5 {
      font-family: 'Roboto', sans-serif;
   }
}

// Social Media - Font Awesone Styles

.fa {
   padding: 20px;
   font-size: 30px;
   width: 50px;
   text-align: center;
   text-decoration: none;
   margin: 5px 2px;

   :hover {
      opacity: 0.7;
   }

   fa-facebook {
      background: #3B5998;
      color: white;
   }

   .fa-twitter {
      background: #55ACEE;
      color: white;
   }

   .fa-google {
      background: #dd4b39;
      color: white;
   }
}

.rdt {
   color: $rdt_color;
}


.bgf {
   color: $bgf_color;
}
