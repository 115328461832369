// header


header {

   div .logo_col {
      min-height: 160px; 
      background: transparent;
      cursor: pointer;
      &::before {
         background-color: $white;
         background-image:url('/theme_nd/style/images/hard/ibblogo_full_400x58.png');
         content: '';
         top: 0.5em;
         left: 0.5em;
         ox-sizing: border-box;
         width: 400px;
         height: 58px;
         position: absolute;
         background-repeat: no-repeat;
         background-size: 400px 58px;

         @include media-breakpoint-down(md) {
            background-image:url('/theme_nd/style/images/hard/ibblogo_full_240x35.png');
            width: 240px;
            height: 35px;
            background-size: 240px 35px;
         }

         @include media-breakpoint-down(sm) {
            display: none;
         }
      }
   }


   img.logo_img {
      width: 100%;
      margin-top: $medium-pad;
//      margin-bottom: $medium-pad;
   }

   .logo_txt {
      font-size: rem(17);
      font-weight: bold;
   }


   .hotline_col {
      padding-left: 0 !important;
      .hotline {
         font-weight: bold;
         margin-top: $small-pad;
         @include media-breakpoint-down(sm) {
//            margin-right: $large-pad;
         }
      }
   }

   .head-btns-right {
      width: auto;
        padding: $medium-pad;
        padding-right: 0;
//        font-size: rem(20);

        i.mdi {
//           font-size: 1.5em;
        }

        @include media-breakpoint-down(lg) {
            font-size: rem(18);
        }
        @include media-breakpoint-down(md) {
           margin: 0 auto !important;
           float: none !important;
        }
   }



   .container-fluid.top_bar {
      @include media-breakpoint-down(sm) {
         padding-right: 0;
         padding-left: 0;   
      }
     & > .row {
         @include media-breakpoint-down(sm) {
            margin-right: -15px;
            margin-left: -15px;
         }
      }
   }


   .top_bar {
//      @extend .shadow_mini;
      .container {
         @include media-breakpoint-down(sm) {
            padding-right: 0;
            padding-left: 0;
         }
      }

      div.eye_row {
      }

      background-color: $top-panel-bg;

       .menu-panel {
//          background-color: $white !important;
          background-color: $robert_main_light !important;
          z-index: 200;

          .nav-link {
             @include media-breakpoint-down(md) {
//                padding: .3em .2em;
             }
          }
       }

       .fg {
          z-index: 10;
       }

       .fg2 {
          z-index: 2;
       }

       .dropdown-menu { z-index: 500; }


   }


   object.logo {
      width: 100%;
      pointer-events: none;
      margin-top: $medium-pad;
      margin-bottom: $large-pad;
      @include media-breakpoint-down(sm) {
         width: 100%;
      }
   }

   div.streams-app {
      // background-color: $light-grey;
      color: $grey;
      .title {
         font-size: rem(11);
      }
      button {
         font-size: rem(11) !important;
         padding: rem(4) !important;
      }
   }

   div.nowplays-app {
      color: $grey;
//      border-top-right-radius: 0 !important;

      .sendung { font-weight: bold; }

      &.musikbox {
         color: $light-grey;
      }
   }

}
