// grid stuff */



.content {
   margin-top: $large-pad;
//   flex-wrap: wrap;

   & > *:first-child {
      margin-top: 0 !important;
   }

   .alert {
      margin-bottom: $large-pad;
   }

}


div.shadow_row {
   margin-top: rem(48);
   z-index: 200;
   @include box-shadow(0, 3px, 3px, rgba(0, 0, 0, 0.1), $inset:false);
}

