.teaser_row {
   padding: $medium-pad;
   min-height: rem(100);
}


div.prod_teaser {
   width: 250px;
   min-height: 100px;
   @include media-breakpoint-down(md) {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $large-pad;
      margin-top: $medium-pad;
   }
   @include media-breakpoint-up(md) {
      position: absolute;
      top: -$triple-pad;
      right: 0;
      z-index: 700;
   }
   div.prod_teaser_action {
      margin-bottom: $medium-pad;
   }
}

div.ZMSTeaserContainer > div {
//   display: inline-block;
//   float: left;
//   width: 200px !important;
   min-height: 150px;
//   @include media-breakpoint-down(sm) {
//      justify-content: center !important;
//      align-items: center !important;
//   }
}

div.ZMSTeaserElement {
//   width: 200px;
}




